import React from "react";

const EventCard = ({
  event,
  expandedEvents,
  expandedDescriptions,
  toggleExpandTitle,
  toggleExpandDescription,
  loading,
  navigate,
  userEvents,
  openModal,
  truncateText
}) => {
  return (
    <div className="card">
      <img
        src={event?.image?.url ? event?.image?.url : "/changes/no_event.png"}
        alt={event.name}
        loading="lazy"
      />
      <div className="event-title">
        {/* <h3 onClick={() => toggleExpandTitle(event._id)}>
          {truncateText(event?.name, 25, expandedEvents[event._id])}{if event?.name?.length > 25 ? "..." : ""} 
        </h3> */}
        <h3 onClick={() => toggleExpandTitle(event._id)}>
          {truncateText(event?.name, 25, expandedEvents[event._id])}
          {event?.name?.length > 25 ? "..." : ""}
        </h3>
      </div>
      <div className="events-card-text">
        <p>
          {truncateText(event.description, 30, expandedDescriptions[event._id])}
          {event.description.length > 30 && (
            <span
              onClick={() => toggleExpandDescription(event._id)}
              className="read-more-link"
            >
              {expandedDescriptions[event._id] ? "..." : "...."}
            </span>
          )}
        </p>
      </div>
      <div className="user-img-stack-container">
        {event?.profilePicStack?.map(
          (user, i) =>
            user?.profileImage?.url && (
              <img key={i} src={user?.profileImage.url} alt="." />
            )
        )}
        {event?.usersCount - event?.profilePicStack?.length > 3 && (
          <div className="more-info">
            <span>+{event?.usersCount - event?.profilePicStack?.length}</span>
          </div>
        )}
      </div>
      <div className="button-container">
        <button
          onClick={() => {
            userEvents?.includes(event._id)
              ? navigate(`/chat/${event._id}`)
              : openModal(event);
          }}
          className="button"
          style={{ borderRadius: "30px", margin: "0 auto !important" }}
        >
          Join Group
        </button>
      </div>
    </div>
  );
};

export default EventCard;
