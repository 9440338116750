import React from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthActions from "redux/auth/actions";
import "./dashboardLayout.css";
import HomeIcon from "./HomeIcon";
import ProfileIcon from "./ProfileIcon";
import Logo from "./Logo";

const { logout } = AuthActions;

const DashboardLayout = props => {
  // console.log(props.isLogin)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = props.token;

  const currentPath = location.pathname;
  const hideNavPaths = ["/"];
  const shouldHideNav = hideNavPaths.includes(currentPath);

  const logoutUser = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <main className="main-container">
      <div className="app-container">
        <header className="logo-header">
          <div className="logo-container">
            <Link to={"/"} className="Logo">
              {/* <img src="/logo/logo.png" alt="logo" /> */}
              <Logo />
              <div className="logo-title">
                <h1>bandlr</h1>
              </div>
            </Link>
          </div>
          {!props.isLogin && (
            <div className="auth-btn">
              <Link to={"/intro"}>
                <button>Sign Up</button>
              </Link>
            </div>
          )}
        </header>
        {shouldHideNav && (
          <nav>
            <Link to="/">
              <HomeIcon />
            </Link>
            <Link to="/profile">
              <ProfileIcon />
            </Link>
          </nav>
        )}
        <Outlet />
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    credits: state.auth.credits,
    isLogin: state.auth.isLogin
  };
};

export default connect(mapStateToProps, {})(DashboardLayout);
