import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  Avatar,
  CircularProgress,
  IconButton,
  Stack
} from "@mui/material";
import { Lock, Send } from "@mui/icons-material";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updateProfilePic } from "services/authServices";
// import AuthActions from "../../../redux/auth/actions";
import AuthActions from "../../redux/auth/actions";
import imageCompression from "browser-image-compression";
import { TextField } from "@mui/material";
import "./LockScreenOverlay.css";
import example1 from "../../assets/images/ex1.png";
import example2 from "../../assets/images/ex2.png";

const {
  success,
  error,
  setUser,
  login,
  setProfilePic,
  setFirstMessage
} = AuthActions;

const LockScreenOverlay = ({
  userId,
  token,
  user,
  groupId,
  hasSentFirstMessage,
  setHasProfileImage,
  submitHandler,
  message,
  handleMessage
}) => {
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [firstMessage, setFirstMessage] = useState("");
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  console.log(hasSentFirstMessage);
  //   console.log(userId)
  //   console.log(token)
  // console.log(user.profileImage)
  // console.log(user.name);
  const events = useSelector(state => state.home.events);
  const currEvent = events.filter(event => event._id === groupId)[0];

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    setPreview(user?.profileImage);
  }, []);

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);

      setUploading(true);
      console.log(`Original File size: ${file.size / 1024 / 1024} MB`);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1280, // Lower dimensions for images typically viewed on mobile devices
        useWebWorker: true,
        quality: 0.75, // Reduce quality for further size reduction
        resizeMethod: "browser-high-quality",
        exifOrientation: true,
        onProgress: function(progress) {
          console.log("Compression Progress: ", progress, "%");
        }
      };
      try {
        const compressedFile = await imageCompression(file, options);
        console.log(
          `Compressed File size: ${compressedFile.size / 1024 / 1024} MB`
        );
        // console.log(file);
        const formData = new FormData();
        formData.append("profileImage", compressedFile);
        // formData.append("public_id",profileImageInfo.public_id)
        formData.append("userId", userId);
        formData.append("name", user.name);

        const data = await updateProfilePic(token, formData);
        if (data.success) {
          success(data.message);
          console.log(data.user);
          setHasProfileImage(true);
          dispatch(setProfilePic(data.user.profileImage.url));
        }
      } catch (error) {
        error(error.message);
        console.error("Error uploading image:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div
      style={{
        // border: "2px solid red",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100svw",
        height: "100svh",
        backdropFilter: "blur(2px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
        flexDirection: "column",
        color: "#fff",
        textAlign: "center",
        padding: "30px",
        boxSizing: "border-box",
        border: "2px solid white"
      }}
    >
      {/* <Lock style={{ fontSize: 80, marginBottom: "20px" }} />
      <Typography variant="h6">Access Restricted</Typography>
      <Typography variant="body1" style={{ marginTop: "10px" }}>
        For the safety of our users, we require everyone to upload their profile
        picture
      </Typography> */}
      <div
        style={{
          // border: "2px solid white",
          width: "300px",
          display: "flex",
          flexDirection: "column",
          gap: 5
        }}
      >
        <h2>For the safety of our users, we require everyone to :</h2>
        <div>1. Upload your profile picture</div>
        <div>2. Write an intro message</div>
      </div>

      <div
        className="user-img-stack-container"
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {currEvent?.profilePicStack?.map((profile, index) => {
          const zIndex = currEvent.profilePicStack.length - index;
          console.log(currEvent.usersCount);
          return (
            <Avatar
              key={profile?._id}
              src={profile?.profileImage?.url}
              alt={profile?.name}
              sx={{
                width: 50,
                height: 50,
                border: "2px solid white",
                marginLeft: "-20px",
                zIndex: zIndex,
                "&:first-of-type": {
                  marginLeft: 0
                }
              }}
            />
          );
        })}
        {currEvent.usersCount - currEvent.profilePicStack.length > 5 && (
          <span
            style={{
              marginLeft: "10px"
            }}
          >
            {" "}
            + {currEvent.usersCount - currEvent.profilePicStack.length} more
          </span>
        )}
      </div>
      {preview ? (
        <Avatar
          src={preview}
          alt="Profile Preview"
          sx={{ width: 100, height: 100, marginTop: "20px" }}
        />
      ) : (
        <Avatar
          sx={{
            width: 80,
            height: 80,
            marginTop: "20px",
            backgroundColor: "gray",
            border: "2px solid white"
          }}
        />
      )}

      {!user.profileImage && (
        <Button
          variant="contained"
          // color="primary"
          style={{
            marginTop: "20px",
            backgroundColor: "black",
            color: "white",
            textTransform: "none",
            borderRadius: 10,
            padding: "0.7rem 3rem",
            width: "100%"
          }}
          onClick={handleUploadClick}
          disabled={uploading}
        >
          {uploading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Upload Profile Picture"
          )}
        </Button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
      />

      <div className="chat-example-container">
        <p>Examples of an intro message</p>
        <img src={example1} alt="example_chat_1" />
        <img src={example2} alt="example_chat_2" />
      </div>

      {!hasSentFirstMessage && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {/* Intro Message Example */}
          <form
            style={{
              padding: "0",
              borderTop: "none",
              width: "100%",
              // border: "2px solid red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
              // height: "100px"
            }}
            onSubmit={submitHandler}
          >
            {/* {suggestions.map((suggestion, index) => (
        <Chip key={index} label={suggestion} onClick={() => handleSuggestionClick(suggestion)} style={{ margin: 2 }} />
      ))} */}
            {/*<Stack
              direction="row"
              // height="100%"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              // border="2px solid red"
            >
              <TextField
                className="custom-textfield"
                style={{
                  flex: 1,
                  marginRight: "10px",
                  backgroundColor: "#0000000D",
                  // border: "none",
                  boxShadow: "none",
                  // border: "2px solid black",
                  outline: "none"
                  // overflow:"hidden",
                  // borderRadius: "55px",
                }}
                placeholder={
                  hasSentFirstMessage ? "Type a message" : "Introduce yourself"
                }
                value={message}
                onChange={handleMessage}
                multiline
                maxRows={4}
                variant="outlined"
                // variant="filled"
              />
              <IconButton
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "0.5rem",
                  "&:hover": { bgcolor: "error.dark" }
                }}
                type="submit"
              >
                <Send />
              </IconButton>
            </Stack>*/}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // border: "2px solid blue",
                width: "100%"
              }}
            >
              <input
                type="text"
                style={{
                  flex: 1,
                  marginRight: "10px",
                  border: "none",
                  backgroundColor: "#333333",
                  // border: "2px solid black",
                  height: "3rem",
                  padding: "10px 20px",
                  outline: "none",
                  borderRadius: "10px",
                  color: "white"
                }}
                placeholder="Write intro message here..."
                value={message}
                onChange={handleMessage}
              />
              <button
                type="submit"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                  padding: "10px 10px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  display: "grid",
                  placeItems: "center"
                }}
                onClick={submitHandler}
              >
                <Send />
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: state.auth.user.userId,
    token: state.auth.accessToken,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, {
  success,
  error,
  setUser,
  login,
  setProfilePic,
  setFirstMessage
})(LockScreenOverlay);
